import React from 'react'

const  Header = () => {
    return (
        <h2 className="font-bold text-xl sm:text-3xl mt-1">Just Announced: Special Support Available for Individuals with Open Workers' Comp Cases.
        </h2>
    )
  
}

export default Header