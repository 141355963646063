import React, { useEffect, useState, useRef } from 'react'

const Question1 = ({ questions, audio, play }) => {
    
  return (
    <div>
        {
            questions.map( (q, index) => {
               
                return <Wait key={ index } q={q} audio={ audio } play={ play } />;

            })
        }
    </div>
  )
}

const Wait = ({q, audio, play}) => {

    const [waiting, setWaiting] = useState(1);

    const messageRef = useRef(null);


    useEffect(() => {

        setTimeout(() => {

            setWaiting(0);

            messageRef.current?.scrollIntoView(
                {
                    behavior: 'smooth',
                    block: 'start'
                }
            )

        }, q.wait)

    }, [waiting, q])

    return (

        <div>

        {
            waiting === 1 ?

            ''

            : 

            <div ref={messageRef}>   

                <Loading q={q} audio={ audio } play={ play } />
            
            </div>

        }
        </div>

    )

}

const Loading = ({q, audio, play}) => {

    
    const [loaded, setLoading] = useState(0);

    useEffect(() => {

        setTimeout(() => {

            setLoading( 1 );

            if (play) {

                audio.play();

            }

        }, q.delay)

    }, [q])

    return (
        <div>
        {
            loaded === 0 ?

                <div className="chat-bubble p-4 bg-gray-200 rounded-md mt-2 ml-3">
                    <div className="typing p-4 flex">
                        <div className="dot bg-gray-500 h-[10px] w-[10px] rounded-full ml-0"></div>
                        <div className="dot bg-gray-500 h-[10px] w-[10px] rounded-full ml-0"></div>
                        <div className="dot bg-gray-500 h-[10px] w-[10px] rounded-full ml-0"></div>
                    </div>
                </div>

            : 
                <div className="w-5/6">
                    <div className="mt-2 ml-3 bg-gray-200 p-3 rounded-lg text-gray-800 shadown-sm w-fit">
                        { q.text }
                    </div>
                </div>

        }
        </div>

    )

}

export default Question1